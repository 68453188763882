import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import moment from "moment-timezone";
import { GetLogs } from "./services";
import { Button, Select } from "./Components";

function Logs() {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState("");
  const [syncQuery, setSyncQuery] = useState("");
  const [range, setRange] = useState(
    localStorage.getItem("logsRange") || "Day"
  );
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) setRefresh(false);
    else {
      (async () => {
        setData(null);
        const { data } = await GetLogs({
          range,
          query: syncQuery,
        });
        setData(data);
      })();
    }
  }, [syncQuery, range, refresh]);

  // JSX

  return (
    <Container>
      <div className="align mb-24">
        <h3 className="mb-0">Logs</h3>
        <div className="log-buttons">
          {renderSearch()}
          <Select
            value={range}
            setValue={onSetRange}
            options={["Hour", "Day", "Week", "Month", "Year"]}
            label={`Range: ${range}`}
          />
          <Button className="icon-button" onClick={() => setRefresh(true)}>
            <i className="fas fa-sync" />
          </Button>
        </div>
      </div>
      {renderData()}
    </Container>
  );

  function onSetRange(value) {
    if (value === range) return;
    localStorage.setItem("logsRange", value);
    setRange(value);
  }

  function renderSearch() {
    return (
      <form
        className="query-wrapper"
        onSubmit={(evt) => {
          evt.preventDefault();
          setSyncQuery(query);
        }}
      >
        <i className="fas fa-search" />
        <input value={query} onChange={(evt) => setQuery(evt.target.value)} />
        <Button title="Search" />
      </form>
    );
  }

  function renderData() {
    if (data) {
      if (data[0])
        return (
          <div className="logs">
            {data.map((log, index) => (
              <Log key={index} message={log.message} time={log.time} />
            ))}
          </div>
        );
      else return <div className="center">No results found</div>;
    }
  }
}

export default Logs;

function Log(props) {
  const { message, time } = props;

  return (
    <div className={"log hspread"}>
      <div>{message}</div>
      <div className="log-time">{moment(time).format("L LTS")}</div>
    </div>
  );
}
