import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Input, Crumb, Button } from "../Components";
import { GetRestaurants } from "../services";

export default function AccessCode() {
  const [email, setEmail] = useState("");
  const [data, setData] = useState(null);

  return (
    <Container>
      <Crumb trail={[["Tools", "/auth/tools"], ["Access Code Search"]]} />
      <h3>Access Code Query</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          style={{ marginBottom: 20 }}
          label="Partner Email"
          value={email}
          onChange={setEmail}
        />
        <Button title="Search" />
      </form>
      {renderData()}
    </Container>
  );

  function renderData() {
    if (!data) return;
    return (
      <div style={{ marginTop: 40 }}>
        {data.map((rest) => (
          <div style={{ marginBottom: 20 }} key={rest.restID}>
            <div>ID: {rest.restID}</div>
            <div>Store Name: {rest.storeName}</div>
            <div>Display Name: {rest.nickname}</div>
            <div>Activated: {rest.isActivated ? "Yes" : "No"}</div>
            <div>Access Code: {renderCode(rest.accessCode)}</div>
          </div>
        ))}
      </div>
    );
  }

  function renderCode(code) {
    if (!code) return "";
    const str = [
      code.substr(0, 4),
      code.substr(4, 4),
      code.substr(8, 4),
      code.substr(12, 4),
    ].join(" ");
    return str;
  }

  // {code && (
  //   <div className="code">
  //     {code.substr(0, 4)} {code.substr(4, 4)} {code.substr(8, 4)}{" "}
  //     {code.substr(12, 4)}
  //   </div>
  // )}

  async function onSubmit() {
    setData(null);
    try {
      const { data } = await GetRestaurants({ email });
      setData(data);
    } catch (e) {
      toast.error("Partner not found.");
    }
  }
}
