import React from "react";

export default function Input(props) {
  const {
    disabled,
    password,
    optional,
    label,
    value,
    onChange,
    maxLength = 1000,
    placeholder,
  } = props;
  let type = "text";
  if (password) type = "password";

  return (
    <div className="mb-8" style={props.style}>
      <div>{label}</div>
      {optional && <span className="gray"> - Optional</span>}
      <input
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        maxLength={maxLength}
        style={{ ...styles.input, ...props.inputStyle }}
      />
    </div>
  );
}

const styles = {
  input: {
    minWidth: 300,
    height: 32,
  },
};
