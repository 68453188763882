/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Table, IconButton, Modal } from "../Components";
import { GetCharges, DelCharge } from "../services";
import "react-datepicker/dist/react-datepicker.css";
import { toDollars } from "../utility";
import { capitalize } from "lodash";

import Charge from "./Charge";
import moment from "moment-timezone";

export default function Router() {
  return (
    <Switch>
      <Route exact path="/auth/charges" component={Charges} />
      <Route exact path="/auth/charges/edit" component={Charge} />
    </Switch>
  );
}

function Charges(props) {
  const [data, setData] = useState(null);
  const [delData, setDelData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await GetCharges();
    setData(data);
  }

  if (!data) return null;

  return (
    <>
      <Modal
        isVisible={delData ? true : false}
        onClose={() => setDelData(null)}
        onSubmit={onDelete}
        title="Delete Charge"
      >
        {delData && (
          <>
            Are you sure you want to delete charge {delData.name} for{" "}
            {delData.restID}?
          </>
        )}
      </Modal>
      <Container>
        <div style={styles.header}>
          <h3 style={{ marginBottom: 0 }}>Charges</h3>
          <IconButton
            style={{ marginLeft: 8 }}
            onClick={() => props.history.push("/auth/charges/edit")}
          />
        </div>
        {renderContent()}
      </Container>
    </>
  );

  function renderContent() {
    if (!data[0]) return <div>No charges to display.</div>;

    return (
      <Table
        data={data}
        cols={["restID", "nickname", "name", "amount", "dueDate"]}
        colNames={{
          restID: "Restaurant ID",
          nickname: "Restaurant",
          name: "Name",
          amount: "Amount",
          dueDate: "Due Date",
        }}
        format={{
          amount: toDollars,
          recurring: (d) => (d ? "Yes" : "No"),
          dueDate: (d) => moment(d).format("ll"),
          type: capitalize,
        }}
        onDelete={(d) => setDelData(d)}
      />
    );
  }

  async function onDelete() {
    setDelData(null);
    const { chargeID } = delData;
    await DelCharge({ chargeID });
    await getData();
  }
}

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
};
