import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Crumb, Input, Button } from "../Components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AddCharge } from "../services";
import moment from "moment-timezone";
import { toast } from "react-toastify";

export default function Charge(props) {
  const [name, setName] = useState("");
  const [restID, setRestID] = useState("");
  const [amount, setAmount] = useState("");
  const [dueDate, setDueDate] = useState(new Date());

  return (
    <Container>
      <Crumb trail={[["Charges", "/auth/charges"], ["Add Charge"]]} />
      <h3>Add Charge</h3>
      {renderInput()}
    </Container>
  );

  function renderInput() {
    return (
      <div>
        <Input label="Restaurant ID" value={restID} onChange={setRestID} />
        <Input label="Name" value={name} onChange={setName} />
        <Input label="Amount ($)" value={amount} onChange={setAmount} />
        <div style={{ marginBottom: 8 }}>
          <div>Due Date</div>
          <DatePicker selected={dueDate} onChange={setDueDate} />
        </div>
        <div style={{ height: 20 }} />
        <Button title="Add Charge" onClick={onSubmit} />
      </div>
    );
  }

  async function onSubmit() {
    const payload = {
      restID,
      amount: Math.round(amount * 100),
      name,
      dueDate: moment(dueDate)
        .tz("America/Los_Angeles")
        .startOf("day")
        .toDate(),
    };
    try {
      await AddCharge(payload);
      props.history.goBack();
    } catch (e) {
      toast.error(e.response.data);
    }
  }
}
