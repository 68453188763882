import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import Database from "./Database";
import Logs from "./Logs";
import Collection from "./Collection";
import Tools from "./Tools";
import Partners from "./Partners";
import Restaurants from "./Restaurants";
import Orders from "./Orders/";
import Metrics from "./Overview";
import Charges from "./Charges";
import { IsAuthorized } from "./services";

export default function Auth(props) {
  const [authorized, setAuthorized] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await IsAuthorized();
      if (!data) return (window.location.href = "/login");
      setAuthorized(data);
      setReady(true);
    })();
  }, []);

  if (!ready) return null;
  if (authorized) return <Router path={props.location.pathname} />;
}

function Router(props) {
  // lg breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const style = { ...styles.content };
  if (isMobile) style.marginLeft = 0;

  return (
    <>
      {renderBackdrop()}
      <div style={style}>
        {renderHeader()}
        <Sidebar
          type={isMobile ? "mobile" : "desktop"}
          path={props.path}
          isOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Switch>
          <Route path="/auth/restaurants" component={Restaurants} />
          <Route exact path="/auth/database" component={Database} />
          <Route path="/auth/charges" component={Charges} />
          <Route path="/auth/database/collection" component={Collection} />
          <Route exact path="/auth/logs" component={Logs} />
          <Route path="/auth/tools" component={Tools} />
          <Route path="/auth/partners" component={Partners} />
          <Route path="/auth/orders" component={Orders} />
          <Route path="/auth/metrics" component={Metrics} />
          <Redirect from="/auth/" to="/auth/metrics" />
        </Switch>
      </div>
    </>
  );

  function renderHeader() {
    if (!isMobile) return;
    return (
      <div style={styles.header}>
        <FaBars onClick={() => setSidebarOpen(true)} />
      </div>
    );
  }

  function renderBackdrop() {
    if (!sidebarOpen) return;
    return (
      <div style={styles.backdrop} onClick={() => setSidebarOpen(false)} />
    );
  }
}

const styles = {
  content: {
    height: "100%",
    transition: ".5s",
    marginLeft: 280,
  },
  header: {
    padding: "0 20px",
    height: 60,
    backgroundColor: "#eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 24,
  },
  backdrop: {
    backgroundColor: "black",
    position: "fixed",
    width: "100%",
    height: "100%",
    opacity: 0.4,
    zIndex: 100,
  },
};
