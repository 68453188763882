import React from "react";

export default function Button(props) {
  const style = { ...styles.button };

  if (props.filled) {
    style.backgroundColor = "var(--theme-color)";
    style.borderColor = "var(--theme-color)";
    style.color = "white";
    style.fontWeight = 500;
  }

  if (props.marginless) style.margin = 0;
  if (props.hide) return null;

  let className = "hover-opacity";
  if (props.animated) className = "anim-button";

  return (
    <button
      onClick={onClick}
      style={{ ...style, ...props.style }}
      disabled={props.disabled}
      type={props.type}
      className={className}
    >
      {props.title || props.children}
    </button>
  );

  function onClick(e) {
    if (props.disabled) return;
    if (props.onClick) props.onClick(e);
  }
}

export function IconButton(props) {
  const Icon = require("react-icons/fa")[props.name || "FaPlus"];
  return (
    <div
      onClick={onClick}
      style={{ ...styles.iconButton, ...props.style }}
      className="hover-opacity"
    >
      <Icon />
    </div>
  );

  function onClick() {
    if (props.disabled) return;
    if (props.onClick) props.onClick();
  }
}

export function Buttons(props) {
  const {
    hide,
    waiting,
    onSubmit,
    hideCancel,
    cancelText = "Back",
    submitText = "Save",
  } = props;

  if (hide) return null;
  return (
    <div style={{ marginTop: 20 }}>
      <Button
        hide={hideCancel}
        onClick={onCancel}
        title={cancelText}
        style={{ marginRight: 8 }}
      />
      <Button filled disabled={waiting} onClick={onSubmit} title={submitText} />
    </div>
  );

  function onCancel() {
    if (props.onCancel) props.onCancel();
  }
}

const styles = {
  button: {
    padding: "3px 14px",
    fontSize: 16,
    borderRadius: 3,
    userSelect: "none",
    backgroundColor: "white",
    border: "1px solid #aaa",
    transition: ".2s",
  },
  iconButton: {
    width: 20,
    height: 20,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: "var(--theme-color)",
    borderRadius: 10,
    userSelect: "none",
  },
};
