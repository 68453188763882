import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";

const periods = [
  "Today",
  "Yesterday",
  "Last 7 Days",
  "Last 4 Weeks",
  "Last 3 Months",
  "Last 12 Months",
  "All Time",
  "Exact Dates",
];

export default function Period(props) {
  const { name, startDate, endDate, setStartDate, setEndDate } = props;

  const [period, setPeriod] = useState("");

  // get init period from localStorage
  useEffect(() => {
    const value = localStorage.getItem(name);
    if (value) setPeriod(value);
    else setPeriod("Last 4 Weeks");
  }, []);

  useEffect(() => {
    const start = moment().startOf("day");
    const end = moment().endOf("day");
    if (period === "Exact Dates") {
      setEndDate(end.toDate());
      setStartDate(end.startOf("month").toDate());
    }
    if (period === "Today") {
      setStartDate(start.toDate());
      setEndDate(end.toDate());
    }
    if (period === "Yesterday") {
      setStartDate(start.subtract(1, "day").toDate());
      setEndDate(end.subtract(1, "day").toDate());
    }
    if (period === "Last 7 Days") {
      setStartDate(start.subtract(6, "days").toDate());
      setEndDate(end.toDate());
    }
    if (period === "Last 4 Weeks") {
      setStartDate(start.subtract(4, "weeks").add(1, "day").toDate());
      setEndDate(end.toDate());
    }
    if (period === "Last 3 Months") {
      setStartDate(start.subtract(3, "months").add(1, "day").toDate());
      setEndDate(end.toDate());
    }
    if (period === "Last 12 Months") {
      setStartDate(start.subtract(11, "months").startOf("month").toDate());
      setEndDate(end.toDate());
    }
    if (period === "All Time") {
      setStartDate(moment("2020", "YYYY").startOf("year").toDate());
      setEndDate(end.toDate());
    }
  }, [period]);

  return (
    <div style={styles.period}>
      <div>
        <span style={styles.label}>Period:</span>
        <select
          style={{ minWidth: 150 }}
          value={period}
          onChange={(e) => {
            const value = e.target.value;
            if (value === period) return;
            if (name) localStorage.setItem(name, value);
            setPeriod(value);
          }}
        >
          {periods.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      {renderDateInputs()}
    </div>
  );

  function renderDateInputs() {
    const style = { ...styles.dateInputs };
    if (period === "Exact Dates") style.width = 316;

    return (
      <div style={style}>
        <div style={{ marginLeft: 8 }}>
          <DatePicker selected={startDate} onChange={setStartDate} />
        </div>
        <div style={{ marginLeft: 8 }}>
          <DatePicker selected={endDate} onChange={setEndDate} />
        </div>
      </div>
    );
  }
}

const styles = {
  dateInputs: {
    display: "flex",
    alignItems: "flex-end",
    width: 0,
    overflow: "hidden",
    transition: ".2s",
  },
  period: {
    display: "flex",
    alignItems: "flex-end",
  },
  label: {
    marginRight: 8,
    fontSize: 15,
  },
};
