import React from "react";

export default function Checkbox(props) {
  const { disabled, label, checked } = props;

  let className = "";
  if (!disabled) className += "form-checkbox";
  if (props.error) className += " delete";

  return (
    <div>
      <div className={className} onClick={onClick}>
        <input disabled={disabled} type="checkbox" readOnly checked={checked} />
        {label}
      </div>
    </div>
  );

  function onClick() {
    if (disabled) return;
    props.onClick();
  }
}
