import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Table } from "./Components/index";
import { GetPartners } from "./services";

export default function Partners() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await GetPartners();
    data.forEach((x) => (x.name = `${x.firstName} ${x.lastName}`));
    setData(data);
  }

  return (
    <Container>
      <h3>Partners</h3>
      {data && <Table data={data} cols={["name", "email", "partnerID"]} />}
    </Container>
  );
}
