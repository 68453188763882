import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Crumb, Input, Button, Select } from "../Components";
import { GetSalesTaxReport, GetVersion, SetVersion } from "../services";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { format } from "d3-format";

export default function SalesTaxReport() {
  const [state, setState] = useState("CA");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "month").endOf("month").toDate()
  );
  const [report, setReport] = useState(null);

  const onSubmit = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    try {
      setReport(null);
      const { data } = await GetSalesTaxReport({ start, end, state });
      setReport(data);
    } catch (e) {
      //
    }
  };

  return (
    <Container>
      <Crumb trail={[["Tools", "/auth/tools"], ["Sales Tax Report"]]} />
      <h3>Sales Tax Report</h3>
      <div style={{ marginBottom: 40 }}>
        <div style={{ marginBottom: 12 }}>
          <div>State</div>
          <Select
            label={state}
            options={["CA", "ID", "NV"]}
            value={state}
            setValue={setState}
            containerStyle={{ marginLeft: 0 }}
          />
        </div>
        <div style={{ marginBottom: 12 }}>
          <div>Start Date</div>
          <DatePicker selected={startDate} onChange={setStartDate} />
        </div>
        <div>
          <div>End Date</div>
          <DatePicker selected={endDate} onChange={setEndDate} />
        </div>
        <Button
          filled
          title="Get Report"
          style={{ marginTop: 20 }}
          onClick={onSubmit}
        />
      </div>
      {report && (
        <table>
          <tbody>
            {Object.keys(report).map((key) => {
              let value = report[key];
              if (value.includes(".")) value = "$" + format(",.2f")(value);
              return (
                <tr key={key}>
                  <td style={{ fontWeight: "500", paddingRight: 30 }}>{key}</td>
                  <td>{value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Container>
  );
}
