import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Crumb, Input, Button } from "../Components";
import { GetVersion, SetVersion } from "../services";
import { toast } from "react-toastify";

export default function AppVersion() {
  // const [mobileVer, setMobileVer] = useState("")
  const [tabletVer, setTabletVer] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await GetVersion({ target: "tablet" });
      setTabletVer(data.version);
    })();
  }, []);

  return (
    <Container>
      <Crumb trail={[["Tools", "/auth/tools"], ["App Version"]]} />
      <h3>App Version</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          style={{ marginBottom: 20 }}
          label="Tablet Version"
          value={tabletVer}
          onChange={setTabletVer}
        />
        <Button title="Save" />
      </form>
    </Container>
  );

  async function onSubmit() {
    await SetVersion({ target: "tablet", version: tabletVer });
    toast.success("Tablet version updated");
  }
}
