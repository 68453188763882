import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ListRestaurants } from "./services";
import { Table, Loader } from "./Components/index";

export default function Partners() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const { data } = await ListRestaurants();
    setData(data);
  }

  const renderAccessCode = (code) => {
    if (!code) return "";
    const str = [
      code.substr(0, 4),
      code.substr(4, 4),
      code.substr(8, 4),
      code.substr(12, 4),
    ].join(" ");
    return str;
  };

  return (
    <Container>
      <h3>Restaurants</h3>
      {renderContent()}
    </Container>
  );

  function renderContent() {
    if (!data) return <Loader />;
    return (
      <Table
        data={data}
        cols={[
          "storeName",
          "nickname",
          "restID",
          "partnerID",
          "isPaused",
          "accessCode",
        ]}
        colNames={{
          storeName: "Restaurant",
          nickname: "Nickname",
          restID: "Restaurant ID",
          partnerID: "Partner ID",
          isPaused: "Status",
          accessCode: "Access Code",
        }}
        effects={{
          isPaused: (d) => {
            if (d)
              return {
                width: 25,
                color: "white",
                backgroundColor: "red",
                borderRadius: 8,
                margin: "auto",
                textAlign: "center",
              };
            else
              return {
                width: 25,
                color: "white",
                backgroundColor: "#02cc66",
                borderRadius: 8,
                margin: "auto",
                textAlign: "center",
              };
          },
        }}
        format={{
          isPaused: (d) => (d ? "✗" : "✓"),
          accessCode: renderAccessCode,
        }}
        onClickRow={(d) => window.open(`https://expresso.io/order/${d.webID}`)}
      />
    );
  }
}
