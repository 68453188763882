import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./Router";
import Login from "./Login";

export default function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        closeButton={false}
      />
      <BrowserRouter>
        <Switch>
          <Route path="/auth" component={Router} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Redirect} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

function Redirect() {
  window.location.href = "/login";
}
