import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Components/Logo";

export default function Sidebar(props) {
  const { type = "desktop", isOpen, setSidebarOpen } = props;

  const style = { ...styles.sidebar };

  if (type === "mobile") {
    if (!isOpen) style.width = 0;
    style.backgroundColor = "#fff";
  }

  return (
    <div style={style} className="sidebar">
      <div style={styles.logo}>
        <Logo />
      </div>
      <div className="sidebar-element-wrapper">
        {sidebarItems.map((item) => (
          <SidebarItem
            key={item.label}
            label={item.label}
            to={item.to}
            active={props.path.includes(item.to)}
            icon={item.icon}
            onClick={() => setSidebarOpen(false)}
          />
        ))}
        <div
          style={styles.sidebarItem}
          onClick={logout}
          className="sidebar-element"
        >
          <i className="fas fa-sign-out-alt" />
          <div>Logout</div>
        </div>
      </div>
    </div>
  );

  function logout() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    window.location.href = "/login";
  }
}

function SidebarItem(props) {
  const { label, to = "", active, icon } = props;

  let className = "sidebar-element";
  if (active) className += " active";
  return (
    <Link
      style={styles.sidebarItem}
      key={label}
      to={to}
      onClick={onClick}
      className={className}
    >
      <i className={"fas " + icon} />
      <div>{label}</div>
    </Link>
  );

  function onClick() {
    if (props.onClick) props.onClick();
  }
}

const sidebarItems = [
  {
    to: "/auth/metrics",
    label: "Metrics",
    icon: "fa-chart-bar",
  },
  {
    to: "/auth/orders",
    label: "Orders",
    icon: "fa-receipt",
  },
  {
    to: "/auth/restaurants",
    label: "Restaurants",
    icon: "fa-store",
  },
  {
    to: "/auth/database",
    label: "Database",
    icon: "fa-database",
  },
  {
    to: "/auth/partners",
    label: "Partners",
    icon: "fa-user-tie",
  },
  {
    to: "/auth/tools",
    label: "Tools",
    icon: "fa-wrench",
  },
  {
    to: "/auth/charges",
    label: "Charges",
    icon: "fa-dollar-sign",
  },
  {
    to: "/auth/logs",
    label: "Logs",
    icon: "fa-stream",
  },
];

const styles = {
  sidebar: {
    zIndex: 10000,
    backgroundColor: "#eee",
    width: 280,
  },
  sidebarItem: {
    fontSize: 16,
    transition: "color ease 0.2s",
  },
  logo: {
    margin: 40,
    marginBottom: 0,
    height: 20,
  },
};
