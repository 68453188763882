import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Input, Checkbox, Button } from "./Components";
import { Authorize, IsAuthorized } from "./services";
import { setToken } from "./utility";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [storeToken, setStoreToken] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await IsAuthorized();
      if (data) window.location.href = "/auth";
    })();
  }, []);

  return (
    <Container>
      <div style={styles.content}>
        <form onSubmit={onSubmit}>
          <Input
            label="Username"
            value={username}
            onChange={setUsername}
            inputStyle={{ width: "100%" }}
          />
          <Input
            password
            label="Password"
            value={password}
            onChange={setPassword}
            inputStyle={{ width: "100%" }}
          />
          <Checkbox
            style={{ marginTop: 12 }}
            label="Keep me signed in"
            checked={storeToken}
            onClick={() => setStoreToken(!storeToken)}
          />
          <div style={{ height: 20 }} />
          <Button
            animated
            style={{ width: "100%", height: 44 }}
            disabled={loading}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );

  // Helpers

  async function onSubmit(evt) {
    evt.preventDefault();
    if (!username || !password) return toast.error("Please complete the form");
    try {
      setLoading(true);
      const token = (await Authorize({ username, password })).data;
      setToken({ store: storeToken, token });
      window.location.href = "/auth";
    } catch (e) {
      setLoading(false);
      toast.error("Invalid username/password combination");
    }
  }
}

const styles = {
  content: {
    maxWidth: 400,
    margin: "auto",
    marginTop: 20,
  },
  header: {
    margin: "40px 0",
    textAlign: "center",
  },
};

export default Login;
