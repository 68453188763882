export function removeItem(arr, item) {
  const i = arr.indexOf(item);
  if (i > -1) arr.splice(i, 1);
}

export function setToken({ store, token }) {
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");
  if (store) localStorage.setItem("token", token);
  else sessionStorage.setItem("token", token);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toDollars(cents) {
  const isNeg = cents < 0;
  const dollars = Math.abs(cents / 100);
  let str = "$" + dollars.toFixed(2);
  if (isNeg) str = `(${str})`;
  return str;
}
