import React from "react";
import logo from "../images/expresso.svg";

export default function Logo(props) {
  const className = props.onClick ? "clickable" : "";
  const height = props.height || 38;

  return (
    <img
      onClick={onClick}
      className={className}
      src={logo}
      alt="expresso_logo"
      style={{ height, ...props.style }}
    />
  );

  function onClick() {
    if (props.onClick) props.onClick();
  }
}
