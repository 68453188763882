import React from "react";

export default function Cart(props) {
  const { items } = props;

  return items.map(renderItem);

  function renderItem(item, i) {
    const { name, unitPrice, quantity, modifiers } = item;
    const total = quantity * unitPrice;

    const style = { ...styles.item };
    if (i === 0) style.marginTop = 0;

    return (
      <div key={i} style={style}>
        <div style={styles.quantity}>{quantity} ×</div>

        <div style={{ flexGrow: 1 }}>
          <div style={styles.itemName}>{name}</div>
          <div style={styles.modifiers}>
            {modifiers.map((mod) => (
              <div key={mod.modID} style={styles.modifier}>
                {mod.name}
              </div>
            ))}
          </div>
        </div>

        <div style={styles.price}>{getPrice(total)}</div>
      </div>
    );
  }

  // convert cents to dollars
  function getPrice(charge) {
    return "$" + (charge / 100).toFixed(2);
  }
}

const styles = {
  item: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  quantity: {
    marginRight: 7,
    whiteSpace: "nowrap",
    minWidth: 30,
  },
  itemName: {
    fontWeight: 500,
    lineHeight: 1.25,
  },
  modifiers: {
    marginTop: 4,
    display: "flex",
    flexWrap: "wrap",
  },
  modifier: {
    padding: "2px 10px",
    borderRadius: 3,
    marginRight: 4,
    marginBottom: 4,
    fontSize: 15,
    backgroundColor: "#eee",
  },
  price: {
    lineHeight: 1.25,
    color: "#777",
    paddingLeft: 10,
  },
};
