import React from "react";

// financial summary
// subtotal, promo, tax, tip, total

export default function Summary(props) {
  const { money } = props;
  const { subtotal, promo = 0, tip = 0, ccFee, proceeds } = money;

  return (
    <>
      <div style={styles.flexRow}>
        <div>Subtotal</div>
        <div>{getAmount(subtotal)}</div>
      </div>
      {promo > 0 && (
        <div style={styles.flexRow}>
          <div>Discount</div>
          <div>{getAmount(promo)}</div>
        </div>
      )}
      <div style={styles.flexRow}>
        <div>Tip</div>
        <div>{getAmount(tip)}</div>
      </div>
      <div style={styles.flexRow}>
        <div>Processing Fee</div>
        <div>{getAmount(-1 * ccFee)}</div>
      </div>
      <div style={styles.flexRow}>
        <div>Proceeds</div>
        <div>{getAmount(proceeds)}</div>
      </div>
    </>
  );
}

function getAmount(cents) {
  const dollars = Math.abs(cents) / 100;
  let str = "$" + dollars.toFixed(2);
  if (cents < 0) str = `(${str})`;
  return str;
}

const styles = {
  bolder: {
    fontWeight: 500,
  },
  flexRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
  },
};
