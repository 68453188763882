import React from "react";
import ReactLoading from "react-loading";

export default function Loader(props) {
  const { color = "var(--theme-color)" } = props;
  return (
    <div style={styles.loader}>
      <ReactLoading type="spokes" color={color} />
    </div>
  );
}

export function Spokes(props) {
  const { color = "var(--theme-color)" } = props;
  return (
    <div style={{ ...styles.loader, ...props.style }}>
      <ReactLoading type="spokes" color={color} />
    </div>
  );
}

const styles = {
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 140,
  },
};
