import React from "react";
import "./table.css";
import { FaTrashAlt } from "react-icons/fa";

export default function Table(props) {
  const { data, cols, colNames = {}, effects, format, onDelete } = props;
  return (
    <table className="app-table">
      <thead>
        <tr>
          {cols.map((x) => (
            <th key={x}>{colNames[x] || x}</th>
          ))}
          {onDelete && <th>Delete</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((x, i) => (
          <tr
            key={i}
            onClick={() => onClickRow(x)}
            style={{ cursor: props.onClickRow ? "pointer" : "auto" }}
          >
            {cols.map((c) => {
              let data = x[c];

              let style = {};
              if (effects && effects[c]) style = effects[c](data);

              let text = data;
              if (format && format[c]) text = format[c](data);

              return (
                <td key={c}>
                  <div style={style}>{text}</div>
                </td>
              );
            })}
            {onDelete && (
              <td>
                <FaTrashAlt
                  onClick={() => props.onDelete(x)}
                  style={{ color: "red" }}
                  className="clickable"
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );

  function onClickRow(data) {
    if (!props.onClickRow) return;
    props.onClickRow(data);
  }
}
