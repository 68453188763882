import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import moment from "moment-timezone";
import { Loader, Period } from "../Components/index";
import { GetOrdersTable } from "../services";
import { toDollars } from "../utility";
import { capitalize } from "lodash";
import MaterialTable from "material-table";
import qs from "query-string";

import Info from "./Info";

export default function Router() {
  return (
    <Switch>
      <Route exact path="/auth/orders" component={Orders} />
      <Route path="/auth/orders/info" component={Info} />
    </Switch>
  );
}

function Orders(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [didInit, setDidInit] = useState(false);

  const tableRef = useRef(null);

  const { search } = qs.parse(props.location.search);

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (!didInit) return setDidInit(true);
    tableRef.current.onQueryChange({ page: 0 });
  }, [startDate, endDate]);

  return (
    <Container style={{ maxWidth: "unset" }}>
      {renderHeader()}
      {renderTable()}
    </Container>
  );

  function renderHeader() {
    return (
      <div className="flex-header" style={{ marginBottom: 20 }}>
        <h3 style={{ marginBottom: 0 }}>Orders</h3>
        <Period
          name="orders_period"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    );
  }

  function renderTable() {
    return (
      <MaterialTable
        tableRef={tableRef}
        style={styles.table}
        components={{
          OverlayLoading: () => (
            <div style={styles.overlay}>
              <Loader style={{ marginTop: 0 }} />
            </div>
          ),
        }}
        options={{
          paging: true,
          pageSizeOptions: [10, 25, 50],
          pageSize: 10,
          draggable: false,
          showTitle: false,
          searchText: search,
        }}
        columns={[
          {
            title: "Submitted",
            field: "created",
            defaultSort: "desc",
            cellStyle: { minWidth: 240 },
            render: (o) => moment(o.created).format("l @ h:mm A"),
          },
          {
            title: "Restaurant",
            field: "restaurant",
            render: (o) => o.storeName,
            cellStyle: { minWidth: 250 },
          },
          {
            title: "Customer",
            field: "name",
            cellStyle: { minWidth: 200 },
            render: (o) => (
              <Link to={`/auth/orders/info?id=${o.orderID}`}>{o.name}</Link>
            ),
          },
          {
            title: "Total",
            field: "total",
            render: (o) => toDollars(o.money.total),
          },
          {
            title: "Items",
            field: "itemCount",
            defaultSort: "desc",
          },
          {
            title: "Order Type",
            field: "orderType",
            render: (o) => capitalize(o.orderType),
          },
          {
            title: "Origin",
            field: "origin",
            lookup: {
              website: "Website",
              "website/mobile": "Mobile Web",
              mobile: "Mobile App",
            },
          },
          { title: "Status", field: "status" },
        ]}
        data={async (query) => {
          const { orderBy, orderDirection, page, pageSize, search } = query;
          const payload = {
            orderBy,
            orderDirection,
            page,
            pageSize,
            search,
            startDate,
            endDate,
          };
          const { data } = await GetOrdersTable(payload);
          return data;
        }}
      />
    );
  }
}

const styles = {
  table: {
    marginBottom: 20,
    boxShadow: "none",
  },
  overlay: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
  },
};
