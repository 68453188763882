import React from "react";
import { Container } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import AccessCodeQuery from "./AccessCodeQuery";
import AddCheckout from "./AddCheckout";
import AppVersion from "./AppVersion";
import SalesTaxReport from "./SalesTaxReport";

export default function Router() {
  return (
    <Switch>
      <Route exact path="/auth/tools" component={Tools} />
      <Route
        exact
        path="/auth/tools/access-code-query"
        component={AccessCodeQuery}
      />
      <Route exact path="/auth/tools/add-checkout" component={AddCheckout} />
      <Route exact path="/auth/tools/app-version" component={AppVersion} />
      <Route
        exact
        path="/auth/tools/sales-tax-report"
        component={SalesTaxReport}
      />
    </Switch>
  );
}

function Tools() {
  return (
    <Container>
      <h3>Tools</h3>
      <Tool link="/auth/tools/access-code-query" title="Access Code Query" />
      <Tool link="/auth/tools/add-checkout" title="Add Checkout" />
      <Tool link="/auth/tools/app-version" title="App Version" />
      <Tool link="/auth/tools/sales-tax-report" title="Sales Tax Report" />
    </Container>
  );
}

function Tool(props) {
  return (
    <div className="tool">
      <Link to={props.link}>{props.title}</Link>
    </div>
  );
}
