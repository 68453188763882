import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight as Angle } from "react-icons/fi";

export default function Crumb(props) {
  const { trail } = props;
  return (
    <div style={styles.crumbs}>
      {trail.map(([title, path], i) => {
        if (!path) return <div key={i}>{title}</div>;
        else
          return (
            <React.Fragment key={i}>
              <Link style={styles.link} to={path}>
                {title}
              </Link>
              <Angle style={styles.icon} />
            </React.Fragment>
          );
      })}
    </div>
  );
}

const styles = {
  crumbs: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  link: {
    color: "var(--theme-color)",
  },
  icon: {
    margin: "0 2px",
    fontSize: 21,
  },
};
