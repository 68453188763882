import React, { useState, useEffect } from "react";
import qs from "query-string";
import { Container, Crumb, Loader } from "../Components/index";
import { GetOrder } from "../services";
import moment from "moment-timezone";
import { origins } from "../lookup";
import { toDollars } from "../utility";

import Cart from "./Cart";
import Summary from "./Summary";

export default function Info(props) {
  const [data, setData] = useState(null);

  const { id: orderID } = qs.parse(props.location.search);

  useEffect(() => {
    if (!orderID) return;
    (async () => {
      const { data } = await GetOrder({ orderID });
      setData(data);
    })();
  }, [orderID]);

  if (!data) return <Loader />;

  return (
    <Container width={500}>
      <Crumb trail={[["Orders", "/auth/orders"], ["Order Details"]]} />
      <h3 style={{ marginBottom: 20 }}>Order Details</h3>
      {renderInfo()}
      <div style={styles.section}>
        <Cart items={data.items} />
      </div>
      <div style={{ ...styles.section, borderBottom: "none" }}>
        <Summary money={data.money} />
      </div>
    </Container>
  );

  function renderInfo() {
    const {
      name,
      orderNumber,
      email,
      phone,
      content,
      status,
      created,
      eta,
      promoCode,
      origin,
      money,
      paymentIntent,
      storeName,
    } = data;

    const { total, tax } = money;

    delete content.Phone;
    delete content.Submitted;

    return (
      <div style={styles.section}>
        <div style={styles.row}>
          <div style={styles.label}>Restaurant</div>
          <div>{storeName}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Customer</div>
          <span
            className="clickable"
            style={{ color: "var(--theme-color)" }}
            onClick={onClickName}
          >
            {name}
          </span>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Email</div>
          <div>{email}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Phone</div>
          <div>{phone}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Order Number</div>
          <div>#{orderNumber}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Submitted</div>
          <div>{moment(created).format("l @ h:mm A")}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Promised</div>
          <div>{moment(eta).format("l @ h:mm A")}</div>
        </div>
        {Object.keys(content).map((label) => (
          <div key={label} style={styles.row}>
            <div style={styles.label}>{label}</div>
            <div>{content[label]}</div>
          </div>
        ))}
        {!!promoCode && (
          <div style={styles.row}>
            <div style={styles.label}>Promo Code</div>
            <div>{promoCode}</div>
          </div>
        )}
        <div style={styles.row}>
          <div style={styles.label}>Origin</div>
          <div>{origins[origin]}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Status</div>
          <div>{status}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Payment Intent</div>
          <div>{paymentIntent}</div>
        </div>
        <div style={styles.row}>
          <div style={styles.label}>Order Total</div>
          <div>{toDollars(total)}</div>
        </div>
        <div style={{ ...styles.row, marginBottom: 0 }}>
          <div style={styles.label}>Tax Withheld</div>
          <div>{toDollars(tax)}</div>
        </div>
      </div>
    );
  }

  function onClickName() {
    localStorage.setItem("orders_period", "All Time");
    props.history.push(`/auth/orders?search=${data.name}`);
  }
}

const styles = {
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #ccc",
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 6,
  },
  label: {
    minWidth: 140,
    fontWeight: "500",
  },
};
