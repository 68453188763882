import React from "react";
import { useMediaQuery } from "react-responsive";

export default function Container(props) {
  const { width = 1000 } = props;

  // sm breakpoint
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  const padding = isMobile ? 20 : 40;
  const x = width + padding * 2;
  const style = {
    margin: "auto",
    maxWidth: x,
    padding,
  };
  return <div style={style}>{props.children}</div>;
}
