import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Crumb, Input, Button, Checkbox } from "../Components";
import { GetRestaurants, GetPartnerData, AddCheckout } from "../services";
import { cloneDeep } from "lodash";

export default function Checkout() {
  const [email, setEmail] = useState("");
  const [partnerData, setPartnerData] = useState(null);
  const [checked, setChecked] = useState([]);
  const [data, setData] = useState(null);

  const [items, setItems] = useState([]);

  // input
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");

  return (
    <Container>
      <Crumb trail={[["Tools", "/auth/tools"], ["Add Checkout"]]} />
      <h3>Add Checkout</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitEmail();
        }}
      >
        <Input
          style={{ marginBottom: 20 }}
          label="Partner Email"
          value={email}
          onChange={setEmail}
        />
        <Button title="Next" />
      </form>
      {renderForm()}
      {renderItems()}
      {renderSubmit()}
      {renderData()}
    </Container>
  );

  function renderForm() {
    if (!partnerData) return;
    return (
      <div style={{ marginTop: 40 }}>
        <div style={{ marginBottom: 20 }}>
          Restaurants
          {partnerData.restaurants.map((rest) => {
            const { restID } = rest;
            return (
              <Checkbox
                key={restID}
                label={rest.nickname}
                checked={checked.includes(restID)}
                onClick={() => {
                  const update = Array.from(checked);
                  if (!checked.includes(restID)) {
                    update.push(restID);
                    setChecked(update);
                  } else {
                    const i = update.indexOf(restID);
                    update.splice(i, 1);
                    setChecked(update);
                  }
                }}
              />
            );
          })}
        </div>
        <Input
          style={{ marginBottom: 20 }}
          label="Quantity"
          value={quantity}
          onChange={setQuantity}
        />
        <Input
          style={{ marginBottom: 20 }}
          label="Name"
          value={name}
          onChange={setName}
        />
        <Input
          style={{ marginBottom: 20 }}
          label="Desc"
          value={desc}
          onChange={setDesc}
        />
        <Input
          style={{ marginBottom: 20 }}
          label="Amount"
          value={amount}
          onChange={setAmount}
        />
        <Button
          title="Add Item"
          onClick={() => {
            const update = cloneDeep(items);
            update.push({ quantity, name, desc, amount });
            setItems(update);
            setQuantity("");
            setName("");
            setDesc("");
            setAmount("");
          }}
        />
      </div>
    );
  }

  function renderItems() {
    if (!items[0]) return;
    return (
      <div style={{ marginTop: 20 }}>
        Checkout Items
        {items.map((x, i) => (
          <div key={i}>
            {x.quantity}x {x.name} {x.desc} {x.amount}
          </div>
        ))}
      </div>
    );
  }

  function renderSubmit() {
    if (!items[0]) return;
    return (
      <Button
        filled
        style={{ marginTop: 40 }}
        title="Submit"
        onClick={onSubmit}
      />
    );
  }

  function renderData() {
    if (!data) return;
    return (
      <div style={{ marginTop: 40 }}>
        <div>Checkout created successfully.</div>
        <div>
          Link:{" "}
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            {data.url}
          </a>
        </div>
      </div>
    );
  }

  async function onSubmitEmail() {
    setData(null);
    setPartnerData(null);
    try {
      const query = { email };
      const keys = ["partnerID"];
      const { data: partnerID } = await GetPartnerData({ query, keys });
      const { data: restaurants } = await GetRestaurants(query);
      setPartnerData({ partnerID, restaurants });
    } catch (e) {
      toast.error("Partner not found.");
    }
  }

  async function onSubmit() {
    if (checked.length < 1)
      return toast.error("Must check at least one restaurant.");
    const payload = {
      partnerID: partnerData.partnerID,
      restaurants: checked,
      items,
    };
    setData(null);
    const { data } = await AddCheckout(payload);
    setData(data);
  }
}
