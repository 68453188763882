import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

function SelectInner(props) {
  const { value, setValue, options, label, containerStyle } = props;
  const [optionsVisible, setOptionsVisible] = useState(false);

  SelectInner.handleClickOutside = () => setOptionsVisible(false);

  return (
    <div className="select-wrapper" style={containerStyle}>
      <button
        className="hover-opacity"
        style={styles.button}
        onClick={() => setOptionsVisible(true)}
      >
        {label}
        <i className="fa fa-chevron-down" />
      </button>
      {optionsVisible && (
        <div className="select-options-wrapper">
          {options.map((option) => (
            <div
              key={option}
              className={`select-option ${option === value ? "active" : ""}`}
              onClick={() => onSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  function onSelect(option) {
    setValue(option);
    setOptionsVisible(false);
  }
}

export default onClickOutside(SelectInner, {
  handleClickOutside: () => SelectInner.handleClickOutside,
});

const styles = {
  button: {
    padding: "3px 14px",
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: "white",
    userSelect: "none",
    border: "1px solid #aaa",
    transition: ".2s",
  },
};
