import axios from "axios";

// Authenticate requests

const api = window.location.host.includes("localhost")
  ? "http://localhost:4999"
  : "https://api.expresso.io";

// const api = "https://api.expresso.io";
// const api = "http://localhost:4999";

export function getToken() {
  return sessionStorage.getItem("token") || localStorage.getItem("token");
}

function headers() {
  const token = getToken();
  return { Authorization: token };
}

// get v2
function get(path, payload) {
  return axios({
    method: "get",
    url: api + path,
    params: payload,
    headers: headers(),
  });
}

// post v2
function post(path, payload) {
  return axios({
    method: "post",
    url: api + path,
    data: payload,
    headers: headers(),
  });
}

// Admin

export function Authorize(payload) {
  return post("/admin/authorize", payload);
}

export function IsAuthorized() {
  return get("/admin/is-auth");
}

export function GetLogs(payload) {
  return get("/admin/logs", payload);
}

// Partner

export function GetRestaurants(payload) {
  return get("/partner/restaurants", payload);
}

export function GetPartnerData(payload) {
  return post("/partner/get-data", payload);
}

export function GetPartners(payload) {
  return post("/partner/list", payload);
}

// Restaurants

export function ListRestaurants(payload) {
  return post("/restaurant/list", payload);
}

// Orders

export function GetOrdersTable(payload) {
  return post("/order/table", payload);
}

export function GetOrder(payload) {
  return post("/order/get", payload);
}

export function ListOrders(payload) {
  return post("/order/list", payload);
}

export function GetOverview(payload) {
  return post("/admin/overview", payload);
}

export function GetSalesTaxReport(payload) {
  return post("/order/sales-tax-report", payload);
}

// Checkout

export function AddCheckout(payload) {
  return post("/checkout/add", payload);
}

// System

export function DeployServer(payload) {
  return post("/system/deploy", payload);
}

export function GetVersion(payload) {
  return get("/system/version", payload);
}

export function SetVersion(payload) {
  return post("/system/set-version", payload);
}

export function GetCharges() {
  return get("/system/charges/list");
}

export function AddCharge(payload) {
  return post("/system/charges/add", payload);
}

export function DelCharge(payload) {
  return post("/system/charges/del", payload);
}

// Database

export function CreateCollection(payload) {
  return post("/database/collections/add", payload);
}

export function GetDatabaseInfo() {
  return get("/database/info");
}

export function GetDocuments(payload) {
  return post("/database/documents/get", payload);
}
