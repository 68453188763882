import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { ResponsiveBar } from "@nivo/bar";
import { format } from "d3-format";
import { useMediaQuery } from "react-responsive";
import { Container, Spokes, Period } from "./Components/index";
import { GetOverview } from "./services";
import { origins } from "./lookup";

export default function Metrics(props) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      setReady(true);
    })();
  }, []);

  return <Container>{renderContent()}</Container>;

  function renderContent() {
    if (!ready) return <Spokes />;
    return <Metric {...props} />;
  }
}

const metrics = ["Sales", "Tips", "Orders"];

function Metric(props) {
  const [data, setData] = useState({});

  const isMobile = useMediaQuery({ query: "(max-width: 1199px)" });

  const initMetric = localStorage.getItem("overview_metric") || "Sales";
  const [metric, setMetric] = useState(initMetric);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [waiting, setWaiting] = useState(true);

  const memoGetData = useCallback(
    async (useWaiting = true) => {
      if (!startDate || !endDate || !metric) return;
      if (useWaiting) setWaiting(true);
      const tz = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await GetOverview({ startDate, endDate, metric, tz });
      setWaiting(false);
      setData(data);
    },
    [startDate, endDate, metric]
  );

  useEffect(() => {
    memoGetData();
  }, [memoGetData]);

  // refresh every min
  useEffect(() => {
    const int = setInterval(() => {
      memoGetData(false);
    }, 60000);
    return () => {
      clearInterval(int);
    };
  }, [memoGetData]);

  const headerStyle = { ...styles.header };
  if (isMobile) headerStyle.display = "block";

  return (
    <>
      <div style={headerStyle}>
        {renderGreeting()}
        {renderPeriodSelect()}
      </div>
      {renderOverview()}
      {renderMetricSelect()}
      {renderChart()}
      <Row style={{ marginBottom: 20 }}>
        <ScrollCard
          {...props}
          waiting={waiting}
          title="Restaurants"
          data={data.rests}
        />
        <ScrollCard
          {...props}
          waiting={waiting}
          title="Users"
          data={data.users}
        />
        <ScrollCard
          waiting={waiting}
          title="Origins"
          data={data.origins}
          lookup={origins}
        />
      </Row>
    </>
  );

  function renderGreeting() {
    const date = new Date();
    const hour = date.getHours();
    let greeting;
    if (hour < 12) greeting = "Good morning";
    if (hour >= 12 && hour < 18) greeting = "Good afternoon";
    if (hour >= 18) greeting = "Good evening";

    const style = { fontSize: 19 };
    if (isMobile) style.marginBottom = 20;

    return <div style={style}>{greeting}.</div>;
  }

  function renderPeriodSelect() {
    return (
      <Period
        name="overview_period"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    );
  }

  function renderOverview() {
    const { count, gross, tips, total, convFees, balance } = data;
    return (
      <Row style={{ marginBottom: 20 }}>
        <Card
          waiting={waiting}
          label="Orders"
          value={count}
          render={(x) => format(",")(x)}
        />

        <Card
          waiting={waiting}
          label="Gross Sales"
          value={gross}
          render={(x) => `$${getAmount(x)}`}
        />
        <Card
          waiting={waiting}
          label="Tips"
          value={tips}
          render={(x) => `$${getAmount(x)}`}
        />
        <Card
          waiting={waiting}
          label="Total Volume"
          value={total}
          render={(x) => `$${getAmount(x)}`}
        />
        <Card
          waiting={waiting}
          label="Conv. Fees"
          value={convFees}
          render={(x) => `$${getAmount(x)}`}
        />
        <Card
          waiting={waiting}
          label="Payable"
          value={balance}
          render={(x) => `$${getAmount(x)}`}
        />
      </Row>
    );
  }

  function renderMetricSelect() {
    const style = { ...styles.metricSelect };
    if (isMobile) style.justifyContent = "flex-start";

    return (
      <div style={style}>
        <div>
          <span style={{ marginRight: 8 }}>Chart:</span>
          <select
            value={metric}
            onChange={(e) => {
              const value = e.target.value;
              if (value === metric) return;
              localStorage.setItem("overview_metric", value);
              setMetric(value);
            }}
          >
            {metrics.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  function renderChart() {
    const { chart: chartData = [] } = data;

    // handle y-axis upper limit
    let maxValue = 0;
    for (const bin of chartData) if (bin.value > maxValue) maxValue = bin.value;
    let limit = "auto";

    if (metric === "Sales" && maxValue < 25) limit = 25;
    if (metric === "Tips" && maxValue < 10) limit = 10;
    if (metric === "Orders" && maxValue < 5) limit = 5;

    // space out x-axis tick labels if needed
    let padding = 0.3;
    let tickXValues = null;
    const n = chartData.length;

    if (n >= 17 && n <= 24) {
      tickXValues = [];
      padding = 0.15;
      for (let i = 0; i < chartData.length; i++) {
        if (i % 2 === 0) tickXValues.push(chartData[i].date);
      }
    }

    if (n === 28) {
      tickXValues = [];
      padding = 0.15;
      for (let i = 0; i < chartData.length; i++) {
        if (i % 3 === 0) tickXValues.push(chartData[i].date);
      }
    }

    // if (n>80) {

    if (n > 28) {
      tickXValues = [];
      padding = 0.15;
      for (let i = 0; i < chartData.length; i++) {
        if (i % 7 === 0) tickXValues.push(chartData[i].date);
      }
    }

    // handle y-axis format
    let formatY = undefined;

    // format for sales/tips
    if (metric === "Sales" || metric === "Tips") {
      formatY = (x) => `$${format(",")(x)}`;
      if (maxValue > 1000) formatY = (x) => `$${format("~s")(x)}`;
    }

    // format for orders
    if (metric === "Orders") {
      formatY = (x) => format(",")(x);
      if (maxValue > 1000) formatY = (x) => format("~s")(x);
    }

    // handle x-axis format
    let formatX = undefined;
    if (n === 12) formatX = (x) => x.split(" ")[0];

    const margin = { bottom: 70, left: 90, right: 20, top: 20 };
    const tickRotation = isMobile ? 90 : 0;

    const chartStyle = { height: "100%" };
    if (waiting) chartStyle.opacity = 0.5;

    return (
      <div style={styles.chartWrapper}>
        <div style={chartStyle}>
          <ResponsiveBar
            data={chartData}
            keys={["value"]}
            indexBy="date"
            margin={margin}
            padding={padding}
            colors={"var(--theme-color)"}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: tickRotation,
              tickValues: tickXValues,
              format: formatX,
              stroke: "blue",
              strokeWidth: 2,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              tickValues: 5,
              format: formatY,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            enableLabel={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={theme}
            tooltip={onHover}
            gridYValues={5}
            maxValue={limit}
          />
        </div>
        {waiting && <Spokes style={styles.chartSpokes} />}
      </div>
    );
  }

  function onHover(data) {
    const { value, index, indexValue } = data;

    const single = value === 1;
    let desc;
    if (metric === "Sales") desc = `$${getAmount(value * 100)}`;
    if (metric === "Tips") desc = `$${getAmount(value * 100)}`;
    if (metric === "Orders")
      desc = `${format(",")(value)} Order${single ? "" : "s"}`;

    let label = indexValue;

    const diff = (endDate - startDate) / (1000 * 3600 * 24);

    if (diff > 10) {
      if (index === 26) label = "Yesterday";
      if (index === 27) label = "Today";
    } else if (diff > 1) {
      if (index === 5) label = "Yesterday";
      if (index === 6) label = "Today";
    }

    if (diff > 40) {
      label = indexValue;
    }

    if (diff > 180) {
      label = label.split(" ");
      label = label[0] + " 20" + label[1];
    }

    if (diff > 400) {
      label = indexValue;
    }

    return (
      <div style={styles.tooltip}>
        <div>{label}</div>
        <div>{desc}</div>
      </div>
    );
  }
}

function Card(props) {
  const { value, label, waiting, render } = props;

  const style = { ...styles.card };
  if (waiting) style.opacity = 0.5;

  let str = value;
  if (render) str = render(str);
  if (value === undefined) str = "--";

  return (
    <Col lg>
      <div style={style}>
        <div style={styles.cardLabel}>{label}</div>
        <div style={styles.cardValue}>{str}</div>
      </div>
    </Col>
  );
}

function ScrollCard(props) {
  const { data = [], title, waiting } = props;
  const names = Object.keys(data);
  names.sort((a, b) => {
    const v1 = data[a];
    const v2 = data[b];
    if (v1 > v2) return -1;
    else return 1;
  });

  const style = { ...styles.scrollCard };
  if (waiting) style.opacity = 0.5;

  return (
    <Col md={6} lg={4}>
      <div style={style}>
        <div style={styles.cardTitle}>{title}</div>
        <div style={styles.scrollCardBody}>
          {names.map((x, i) => {
            const style = { ...styles.cardRow };
            if (i === 0) style.border = "none";

            let label = x;
            if (props.lookup) label = props.lookup[label];

            return (
              <div key={i} style={style}>
                <div onClick={() => onClickName(x)}>
                  <div className={props.history ? "link" : ""}>{label}</div>
                </div>
                <div>{format(",")(data[x])}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Col>
  );

  function onClickName(name) {
    if (props.history) {
      localStorage.setItem("orders_period", "All Time");
      props.history.push(`/auth/orders?search=${name}`);
    }
  }
}

function getAmount(cents) {
  const dollars = cents / 100;
  if (dollars === 0) return "0";
  return format(",.2f")(dollars);
}

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  tooltip: {
    backgroundColor: "#000000aa",
    padding: "4px 8px",
    borderRadius: 4,
    color: "white",
    textAlign: "center",
  },
  metricSelect: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  chartWrapper: {
    height: 420,
    marginBottom: 30,
    marginLeft: -40,
    marginRight: -20,
    position: "relative",
  },
  scrollCard: {
    height: 400,
    backgroundColor: "white",
    marginBottom: 16,
    boxShadow:
      "0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
  },
  scrollCardBody: {
    overflowY: "auto",
    padding: "10px 20px",
  },
  cardTitle: {
    padding: 20,
    paddingBottom: 10,
    fontSize: 19,
  },
  cardRow: {
    borderTop: "1px solid #ccc",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0",
  },
  chartSpokes: {
    position: "absolute",
    top: 0,
    marginTop: 140,
  },
  card: {
    minHeight: 100,
    padding: 10,
    backgroundColor: "white",
    marginBottom: 16,
    boxShadow: "var(--generic-shadow)",
    borderRadius: 4,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardLabel: {
    fontSize: 15,
    color: "#555",
  },
  cardValue: {
    fontSize: 18,
  },
};

const theme = {
  fontSize: 16,
  fontFamily: "Rubik",
};
